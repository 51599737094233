#sidebar-menu > ul > li > a {
  padding: 8px 30px;
}

.tooltip-inner {
  max-width: 300px;
}

.modal-title {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.form-control:disabled,
.form-control[readonly],
.custom-select:disabled {
  background-color: #f9fafb;
}

.custom-radio
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: #005697;
}

.custom-radio.custom-control-inline .invalid-feedback {
  margin-left: 3px;
}

.custom-checkbox
  .custom-control-input:disabled:checked
  ~ .custom-control-label::before {
  background-color: #005697;
}

.hide-invalid-feedback .invalid-feedback {
  display: none;
}

.hide-invalid-feedback + .invalid-feedback {
  display: block;
}

.bg-grey {
  background-color: #f3f4f7;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.dropzone p {
  margin-bottom: 0;
  font-weight: bold;
  color: #005697;
}

.dropzone-thumbnails {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;
}

.dropzone-thumbnails > div {
  border-radius: 2px;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100px;
  text-align: center;
}

.dropzone-thumbnails > div > div {
  display: inline-flex;
  box-sizing: border-box;
  border: 1px solid #eaeaea;
  min-width: 0;
  width: 100px;
  height: 100px;
  padding: 4px;
  overflow: hidden;
}

.dropzone-thumbnails img {
  display: block;
  width: 100%;
  height: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

@media (min-width: 576px) {
  .w-md-auto {
    width: auto !important;
  }
}

@media (min-width: 992px) {
  .w-lg-auto {
    width: auto !important;
  }

  .request-statistics .col {
    max-width: 20%;
  }
}

a.blank_anchor:after {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
  margin: 0 3px 0 5px;
}

.font-weight-bold label {
  font-weight: 700;
}
